/* Common Styles */
.hero-container {
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
}

.image-container {
  position: relative;
  height: 75%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  width: 100%; /* Ensure content takes full width */
}

.hero-heading {
  font-size: 2rem;
  margin: 1rem 0;
  color: #fff;
  font-family: 'YourCustomSerifFont', serif;
}

.hero-subheading {
  margin-bottom: 2rem;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

.btn-hireme {
  padding: 0.5rem 1rem;
}

.welcome-text {
  font-weight: bold;
  color: #fff;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 3;
}

.image1 {
  width: 100%;
  border-radius: 1%;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .hero-heading {
    font-size: 1.5rem; /* Adjust as needed for smaller screens */
  }

  .hero-subheading {
    font-size: 1rem; /* Adjust as needed for smaller screens */
    margin-bottom: 1rem; /* Reduce margin for smaller screens */
  }

  .btn-hireme {
    font-size: 0.8rem; /* Adjust as needed for smaller screens */
  }

  .welcome-text {
    font-size: 0.8rem; /* Adjust as needed for smaller screens */
    bottom: 10px; /* Adjust position for smaller screens */
    left: 10px; /* Adjust position for smaller screens */
  }
}