.stroke-text {
  color: transparent;
  font-family: 'YourCustomSerifFont', serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  font-size: 24px; /* Adjust the font size as needed */
}

.App {
  background: rgb(255, 255, 255);
}