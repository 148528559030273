.technologys-container {
  padding: 1rem;
}

.technologys-heading {
  text-align: center;
}

.technology-content {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  justify-content: center;
  /* Apply special styles here */
}

.technology-slide {
  border: 1px solid goldenrod;
}

.technology-card-content {
  text-align: center;
  /* Apply special styles here */
}

.technology-card-content > :nth-child(1) {
  text-align: center;
  /* Apply special styles here */
}

.technology-card-content > :nth-child(2) {
  text-align: center;
  /* Apply special styles here */
}

.technology-card-content > :nth-child(3) {
  text-align: center;
  /* Apply special styles here */
}

@media screen and (max-width: 768px) {
  .technologys-container {
    padding: 2rem;
  }

  .technology-content {
    display: flex;
    flex-direction: column;
  }

  .technologys-slide {
    width: fit-content;
    height: fit-content;
  }

  .card-container{
  width: 10px;
  }

  .pythonanywhereimage{
    width: 10px;
  }
}

.technologys-container {
  padding: 1rem;
}

.technologys-heading {
  text-align: center;
}

.technology-content {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  justify-content: center;
  /* Apply special styles here */
}

.technology-slide {
  border: 1px solid goldenrod;
}

.technology-card-content {
  text-align: center;
  /* Apply special styles here */
}

.technology-card-content > :nth-child(1) {
  text-align: center;
  /* Apply special styles here */
}

.technology-card-content > :nth-child(2) {
  text-align: center;
  /* Apply special styles here */
}

.technology-card-content > :nth-child(3) {
  text-align: center;
  /* Apply special styles here */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  width: fit-content;
  height: fit-content;
}

body {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font to the whole page */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font to headings */
}

/* Apply Roboto to specific elements */
p, span, div {
  font-family: 'Roboto', sans-serif; /* Apply Roboto to paragraphs, spans, and divs */
}