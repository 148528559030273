.Demos-container {
  padding: 50px;
  background-color: #ffffff;
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font */
}

.Demos-heading {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font */
}

.Demos-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Demo {
  width: 45%;
  margin-bottom: 20px;
  padding: 30px; /* Increased padding */
  border: 1px solid #ccc;
  background-color: #fff;
}

.Demo h2 {
  margin-bottom: 10px;
  color: #333333;
}

.Demo p {
  color: #666666;
}

.Demo-video {
  width: 100%;
  text-align: center;
}

.Demo-video video {
  max-width: 100%;
  height: auto;
}