.achievements-container {
  padding: 10px;
  background-color: #ffffff;
}

.achievements-heading {
  text-align: center;
  margin-bottom: 20px;
}

.achievements-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.achievement {
  width: 75%;
  margin-bottom: 20px;
  padding: 30px; /* Increased padding */
  border: 1px solid #ccc;
  background-color: #fff;
  text-align: center;
}

.achievement h2 {
  margin-bottom: 10px;
  color: #333333;
}

.achievement p {
  color: #666666;
}

.achievement-video {
  width: 100%;
  text-align: center;
}

.achievement-video video {
  max-width: 100%;
  height: auto;
}

body {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font to the whole page */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font to headings */
}

/* Apply Roboto to specific elements */
p, span, div {
  font-family: 'Roboto', sans-serif; /* Apply Roboto to paragraphs, spans, and divs */
}

@media screen and (max-width: 768px) {
  .achievement {
    width: 100%; /* Make achievements take up full width on smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .achievement img {
    max-width: 100%; /* Make achievement images take up full width */
    height: auto; /* Maintain aspect ratio */
  }
}