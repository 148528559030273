body {
    font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font to the whole page */
  }
  
  /* Apply Roboto to specific elements */
  p, span, div {
    font-family: 'Roboto', sans-serif; /* Apply Roboto to paragraphs, spans, and divs */
  }
  
  /* Footer Container */
  .footer-container {
    padding: 2rem;
    color: rgb(0, 0, 0);
    font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font to the footer */
  }
  
  /* Footer Content */
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-align: center;
  }
  
  /* Footer Content Paragraphs */
  .footer-content p {
    margin: 0.5rem;
  }