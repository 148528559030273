.header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sub-header {
    margin-bottom: 10px;
  }
  
  .button-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .button {
    background-color: #007BFF !important;
    color: #FFFFFF !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 8px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .button img {
    margin-right: 10px;
    max-height: 30px; /* Adjust as needed */
  }
  
  .button:hover {
    background-color: #0056b3 !important;
  }  

  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .pdf-icon {
    max-width: 100px; /* Adjust as needed */
  }
  
  .file-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .file-name {
    margin: 0 10px;
  }
  
  .download-link {
    cursor: pointer;
    color: #007BFF;a.header {
      text-align: center;
      margin-bottom: 20px;
    }
    
    .sub-header {
      margin-bottom: 10px;
    }
    
    .button-container {
      text-align: center;
      margin-bottom: 20px;
    }
    
    .button {
      background-color: #007BFF !important;
      color: #FFFFFF !important;
      border: none !important;
      padding: 10px 20px !important;
      border-radius: 8px !important;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    
    .button img {
      margin-right: 10px;
      max-height: 30px;
    }
    
    .button:hover {
      background-color: #0056b3 !important;
    }
    
    .center-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .pdf-icon {
      max-width: 100px;
    }
    
    .file-container {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 10px;
    }
    
    .file-name {
      font-size: 18px;
      margin: 0 10px;
    }
    
    .download-link {
      cursor: pointer;
      color: #007BFF;
    }
    
    .download-link:hover {
      text-decoration: underline;
    }
    
    .file-row {
      display: flex;
      justify-content: space-between;
      margin: 10px;
    }
    
    .file-container {
      padding: 10px;
    }
    
  }
  
  .download-link:hover {
    text-decoration: underline;
  }

  .file-row {
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }

  .file-container {
    padding: 10px;
  }

  .file-title {
    margin-top: 5px;
  }

  .header1,
  .header,
.sub-header,
.button,
.button img,
.pdf-icon,
.file-name,
.download-link,
.file-title {
  font-family: 'YourCustomSerifFont', serif;
}

/* Apply fonts to specific elements */
.file-name {
  font-size: 18px;
}

.download-link {
  color: #007BFF;
}

.download-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  /* Adjustments for mobile devices */

  .header1 {
    font-size: 24px; /* Increase font size for headers */
  }

  .header {
    font-size: 20px; /* Increase font size for category headers */
  }

  .pdf-icon {
    max-width: 80px; /* Adjust max-width for PDF icons */
  }

  .file-title {
    margin-top: 10px; /* Increase space between file title and icon */
    font-size: 16px; /* Adjust font size for file title */
  }

  .file-name {
    font-size: 16px; /* Adjust font size for file names */
  }
}