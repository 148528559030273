.about-us-container {
    padding: 50px;
    background-color: #ffffff;
  }
  
  .about-us-heading {
    text-align: center;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .member {
    width: 18%; /* Adjust as needed */
    margin: 17px;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    text-align: center;
    display: inline-block; /* Keep members in the same row */
  }  
  
  .member h2 {
    margin-bottom: 10px;
    color: #333333;
  }
  
  .member p {
    color: #666666;
  }

  .supervisors {
    text-align: center;
  }

/* Style for FontAwesome icons */
.social-links a {
  text-decoration: none;
  margin: 0 10px;
}

.social-links a .icon {
  color: #007bff; /* Change the color as needed */
  font-size: x-large;
}

/* On hover effect for icons */
.social-links a:hover .icon {
  color: #33e114; /* Change the hover color as needed */
}

body {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font to the whole page */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font to headings */
}

/* Apply Roboto to specific elements */
p, span, div {
  font-family: 'Roboto', sans-serif; /* Apply Roboto to paragraphs, spans, and divs */
}

/* Aboutus.css */

.about-us-container {
  padding: 20px; /* Add padding to the container */
}

.about-us-heading {
  margin-bottom: 20px; /* Add margin to the heading */
}

.member {
  margin-bottom: 20px; /* Add margin to each member */
}

.member-image {
  max-width: 78%; /* Ensure the image doesn't overflow its container */
}

.social-links {
  margin-top: 10px; /* Add margin to the social links */
}

/* Add any additional styles you need */


@media screen and (max-width: 768px) {
  .about-us-container {
    padding: 10px; /* Further reduce padding for smaller screens */
  }

  .about-us-heading {
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }

  .member {
    width: 100%; /* Full width for mobile screens */
  }
}