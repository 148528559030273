.milestone-container {
    text-align: center;
    padding: 10px 0;
  }
  
  .milestone-content {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .milestone-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .milestone-card {
    display: flex;
    align-items: center;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
    width: 80%;
  }
  
  .milestone-side {
    position: relative;
  }
  
  .milestone-side-1 {
    width: 40px;
    height: 40px;
    background-color: #2cb819;
    border-radius: 50%;
  }
  
  .milestone-marker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
  }
  
  /* .arrow-down {
    top: -20px;
    bottom: auto;
    border-color: transparent transparent #ccc transparent;
  } */
  
  .milestone-side-2,
  .milestone-side-3 {
    padding: 0 20px;
  }
  
  /* ... (previous CSS) ... */

.milestone-title {
    font-weight: bold;
    color: #333;
  }
  
  .milestone-date {
    color: #555;
  }
  
  /* ... (previous CSS) ... */  
  
  h2 {
    margin-bottom: 20px;
    color: #333;
  }

  .up-arrow {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0;
    border-color: #007bff transparent transparent transparent;
  }

  /* .arrow-down {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 20px;
    height: 20px;
    background-color: #007bff;
    border: none;
  } */
  
  /* .arrow-down::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #fff;
  } */

  /* Add this to your CSS */

/* .milestone-card {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
  }
  
  .milestone-card.active {
    opacity: 1;
    transform: translateY(0);
  } */
  
  /* Add this to your CSS */

.milestone-card:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }

.milestone-card {
  background-color: #ffffff; /* Light Pink */
  border-color: #3fd58c; /* Dark Pink */
}

.milestone-date {
  color: #FF5722; /* Deep Orange */
}

/* Add hover effect */
.milestone-card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.arrow-down {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #007bff;
}

.hero-heading {
  font-family: 'YourCustomSerifFont', serif;
}

.hero-subheading {
  font-family: 'Roboto', sans-serif;
}

/* Apply fonts to the new section */
.milestone-container {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font */
  /* Add other styles as needed */
}

/* Apply fonts to elements in the new section */
.milestone-content h2 {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font */
}

.milestone-content p {
  font-family: 'Roboto', sans-serif; /* Apply Roboto */
}

.column h2 {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font */
}

.column p {
  font-family: 'Roboto', sans-serif; /* Apply Roboto */
}

@media screen and (max-width: 768px) {
  .milestone-card {
      width: 80%; /* Full width for smaller screens */
      box-sizing: border-box; /* Ensure padding is included in width */
  }

  .milestone-card.active {
      opacity: 1;
      transform: translateY(0);
  }

  .milestone-card:hover {
      transform: scale(1.05);
      transition: transform 0.3s ease;
  }

  .milestone-card-container {
      align-items: center;
  }

  .milestone-side-1 {
      margin-bottom: 10px; /* Reduce space between marker and content */
  }

  .milestone-side-2,
  .milestone-side-3 {
      padding: 0;
  }

  .milestone-side {
      text-align: center;
  }
}