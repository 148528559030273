/* Contact Container */
.contact-container {
  background-color: #ffffff;
  padding: 80px 0;
}

/* Contact Heading */
.contact-heading {
  text-align: center;
  color: #333;
  margin-bottom: 40px;
}

/* Contact Form */
.contact-form {
  display: flex;
  justify-content: center;
}

/* Form Container */
.contact-message {
  max-width: 600px;
  width: 100%;
}

/* Text Field Styles */
.contact-textfield,
.contact-textarea {
  width: 100%;
  margin-bottom: 20px;
}

/* Button Styles */
.contact-btn {
  background-color: #32dc14;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 70%;
  font-size: 18px;
}

.contact-btn:hover {
  background-color: #28e34a;
}

/* Textarea Styles */
.contact-textarea {
  min-height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Media Query for Mobile */
@media screen and (max-width: 768px) {
  .contact-heading {
    font-size: 28px;
  }
}

body {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font to the whole page */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font to headings */
}

/* Apply Roboto to specific elements */
p, span, div {
  font-family: 'Roboto', sans-serif; /* Apply Roboto to paragraphs, spans, and divs */
}

@media screen and (max-width: 768px) {
  .contact-heading {
    font-size: 20px; /* Adjust font size for smaller screens */
  }
  
  .contact-btn {
    width: 100%; /* Make the button full width on smaller screens */
  }
}