/* Add your existing CSS here... */

.domain-container {
    padding: 50px;
    background-color: #ffffff;
}

.domain-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
}

.column {
    width: calc(47% - 20px);
    margin-right: 20px;
    background-color: #f5f5f5;
    padding: 20px;
}

.card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    -webkit-transition: transform 0.2s;
    -moz-transition: transform 0.2s;
    -ms-transition: transform 0.2s;
    -o-transition: transform 0.2s;
}

.card:hover {
    transform: scale(1.03);
}

h2 {
    margin-bottom: 10px;
    color: #333333;
}

p {
    color: #666666;
    line-height: 1.6;
}

.hero-heading {
    font-family: 'YourCustomSerifFont', serif;
  }
  
  .hero-subheading {
    font-family: 'Roboto', sans-serif;
  }
  
  /* Apply fonts to the new section */
  .domain-container {
    font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font */
    /* Add other styles as needed */
  }
  
  /* Apply fonts to elements in the new section */
  .domain-content h2 {
    font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font */
  }
  
  .domain-content p {
    font-family: 'Roboto', sans-serif; /* Apply Roboto */
  }
  
  .column h2 {
    font-family: 'YourCustomSerifFont', serif; /* Apply custom serif font */
  }
  
  .column p {
    font-family: 'Roboto', sans-serif; /* Apply Roboto */
  }

  @media screen and (max-width: 768px) {
    .column {
        width: 100%; /* Full width for smaller screens */
        margin-right: 0; /* Remove right margin for the last column */
    }
}